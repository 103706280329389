/* SCROLLBAR STYLING*/
::-webkit-scrollbar {
    width: 8px;
}

:hover::-webkit-scrollbar {
    width: 8px;
}

:hover::-webkit-scrollbar:horizontal {
    height: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 20px;
    /*background: rgba(0, 0, 0, 0.2);
    */
}

::-webkit-scrollbar-thumb {
    /*background: transparent;*/
    /*border-radius: 20px;
    background: rgba(255, 255, 255, 0.8);
    */
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
}

:hover::-webkit-scrollbar-thumb {
    /*
    background: rgba(255, 255, 255, 0.4);
    */
    background: rgba(0, 0, 0, 0.3);
}